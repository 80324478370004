.js-SharedCss-__alert__alert__OgMSM {
  box-shadow: inset 0 2px 4px #fff3;
  z-index: 9999;
  opacity: .95;
  -webkit-box-shadow: 0 2px 2px #00000021;
  border-radius: 4px;
  min-height: 30px;
  margin: 0;
  padding: 2px 15px;
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  -moz-box-shadow: inset 0 2px 4px #fff3;
}

.js-SharedCss-__alert__alert__OgMSM.js-SharedCss-__alert__render-with-menu__aJNR- {
  left: 160px;
}

.js-SharedCss-__alert__alert__OgMSM.js-SharedCss-__alert__error__vbKGB {
  color: #333;
  background: -moz-linear-gradient(#fffacd, #ffe8a7);
  background: -moz-linear-gradient(#fffacd, #ffe8a7);
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#fffacd), to(#ffe8a7));
  border-top: 1px solid #fef4d8;
  border-bottom: 1px solid #ebc38c;
}

.js-SharedCss-__alert__alert__OgMSM.js-SharedCss-__alert__success__gD8zV {
  color: #300b30;
  background: -moz-linear-gradient(#84cc87, #5cad55);
  background: -moz-linear-gradient(#84cc87, #5cad55);
  background: -webkit-gradient(linear, 0 0, 0 100%, from(#84cc87), to(#5cad55));
  border-top: 1px solid #ace8a7;
  border-bottom: 1px solid #607e5f;
}

.js-SharedCss-__form__container__mCfkD {
  color: #333;
  background: #f8f8f8;
  overflow: auto;
  height: 100% !important;
}

.js-SharedCss-__form__header__x9FGu {
  background: #e6f2ff;
  border-bottom: 4px solid #fff;
  padding-bottom: 70px;
  position: relative;
}

.js-SharedCss-__form__logo__4MIil {
  border-bottom: 3px double #accfe5;
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  padding-top: 20px;
  padding-bottom: 10px;
  position: relative;
}

.js-SharedCss-__form__logo__4MIil h1 {
  text-indent: -119988px;
  text-align: left;
  background-image: url("blueleaf.910e97c1.png");
  background-position: 50%;
  background-repeat: no-repeat;
  width: 133px;
  height: 32px;
  overflow: hidden;
}

.js-SharedCss-__form__header_text__hL6le {
  width: 100%;
  max-width: 660px;
  margin: 25px auto 0;
  line-height: 40px;
}

.js-SharedCss-__form__header_text__hL6le h2 {
  color: #3f5f7f;
  clear: both;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: Crete Round, Georgia, Times New Roman, Times, serif;
  font-size: 28px;
  display: inline;
}

.js-SharedCss-__form__header_text__hL6le p {
  color: #6baad1;
  text-shadow: 0 1px 1px #fff;
  border-left: 1px solid #accfe5;
  margin-left: 10px;
  padding-left: 10px;
  font-family: Crete Round, Georgia, Times New Roman, Times, serif;
  font-size: 18px;
  font-style: italic;
  display: inline;
}

.js-SharedCss-__form__form_container__8t0mS {
  -o-box-shadow: 0 0 0 4px #0000001a;
  background: #fff;
  border: 1px solid #ccc;
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  top: -60px;
  overflow: hidden;
  box-shadow: 0 0 0 4px #0000001a;
}

.js-SharedCss-__form__label__-4ECi {
  float: none;
  color: #222;
  width: auto;
  margin-bottom: 5px;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
  display: block;
}

.js-SharedCss-__form__input_field__BVoWy {
  border: 1px solid #ccc;
  width: 100%;
  max-width: 344px;
  height: 40px;
  padding: 6px;
  display: inline-block;
}

.js-SharedCss-__form__fieldset__lXtYM {
  margin-bottom: 25px;
}

.js-SharedCss-__form__submit_btn__iyc-e {
  cursor: pointer;
  color: #fcfcfc;
  text-shadow: 0 -1px 1px #a64767;
  text-align: center;
  background-color: #0079de;
  background-image: -webkit-linear-gradient(top, #0079de 0% 50%, #0071cf 51% 100%);
  border: none;
  border-radius: 6px;
  padding: 10px 10px 12px;
  font-family: helvetica neue, helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
}

.js-SharedCss-__form__link__SAM0A {
  color: #16a4de;
  margin-left: 20px;
  font-size: 12px;
  text-decoration: none;
}

.js-SharedCss-__form__form_description__l1S3c {
  max-width: 40em;
  margin-bottom: 1em;
  font-size: 14px;
  line-height: 1.5em;
}

.js-SharedCss-__form__checkbox__tyP1E {
  margin-left: 5px;
}

.js-SharedCss-__form__forgot_password_link__qCRzv {
  color: #333;
}

.js-SharedCss-__form__resend_confirmation_link__irSTM {
  color: #333;
  cursor: pointer;
  text-decoration: underline;
}

.js-SharedComponents-__footer__footer__4EWmk {
  text-align: center;
  width: 100%;
  max-width: 660px;
  margin: 0 auto;
  font-size: 11px;
  padding: 0 0 20px !important;
}

.js-SharedComponents-__footer__legal__o8sxM {
  color: #aaa;
}

.js-SharedComponents-__footer__legal__o8sxM:before {
  content: "©";
  margin-right: 3px;
}

.js-AdvisorGenericLogin-__index__generic_container__miL3T {
  -webkit-box-sizing: border-box;
  background-image: url("black_8.f82fe476.png");
  background-repeat: repeat;
  width: 700px;
  margin: 125px auto 0;
  padding: 10px;
  position: relative;
}

.js-AdvisorGenericLogin-__index__generic_container_inner__cKGRE {
  -o-box-shadow: 0px 0px 5px #0003;
  background-color: #fff;
  background-image: url("poweredby_bl_hz_25px.073e344d.png");
  background-position: 463px 30px;
  background-repeat: no-repeat;
  height: 500px;
  margin: 0;
  padding: 50px 40px;
  box-shadow: 0 0 5px #0003;
}

.js-AdvisorGenericLogin-__index__header__ZKBCA {
  background: none;
  border-bottom: 3px double #e6e6e6;
  padding: 0 0 18px;
  position: relative;
}

.js-AdvisorGenericLogin-__index__pic__bwc41 {
  width: 100px;
  height: 100px;
}

.js-AdvisorGenericLogin-__index__pic__bwc41 img {
  -o-box-shadow: 0px 0px 3px #0003;
  width: 100px;
  height: 100px;
  box-shadow: 0 0 3px #0003;
}

.js-AdvisorGenericLogin-__index__brand__n8gne {
  margin: 20px 0 0 120px;
  position: absolute;
  top: 0;
}

.js-AdvisorGenericLogin-__index__sign_in_to__LV2gc {
  color: #ccc;
  padding: 0 0 5px;
  font-size: 14px;
  font-weight: normal;
  display: block;
}

.js-AdvisorGenericLogin-__index__advisor_title_container__AiRxF {
  color: #0b313a;
  min-height: 34px;
  font-size: 28px;
  font-weight: bold;
}

.js-AdvisorGenericLogin-__index__advisor_name__zN5oV {
  color: #0b313a;
  margin: 0;
  padding: 2px 0;
  font-size: 18px;
  font-weight: 300;
}

.js-AdvisorGenericLogin-__index__form_container__t5C5j {
  margin-top: 50px;
}

.js-AdvisorGenericLogin-__index__form_container__t5C5j form {
  float: left;
  width: 300px;
}

.js-AdvisorGenericLogin-__index__fieldset__eyudN {
  margin-bottom: 20px;
}

.js-AdvisorGenericLogin-__index__label__3v5KC {
  color: #999;
  float: none;
  width: auto;
  margin-bottom: 5px;
  padding: 0;
  font-size: 14px;
  font-weight: normal;
  display: block;
}

.js-AdvisorGenericLogin-__index__input_field__IliKn {
  border: 1px solid #ccc;
  width: 100%;
  max-width: 344px;
  height: 40px;
  padding: 6px;
  display: inline-block;
}

.js-AdvisorGenericLogin-__index__message__Lzlaq {
  text-shadow: 1px 1px #fff;
  background-color: #f6f6f6;
  width: 210px;
  margin: 0 0 0 350px;
  padding: 30px 20px;
}

.js-AdvisorGenericLogin-__index__message_header__fAr95 {
  color: #444;
  clear: none;
  margin: 0 0 20px;
  font-family: georgia, times, times new roman, serif;
  font-size: 16px;
  line-height: 20px;
}

.js-AdvisorGenericLogin-__index__message_body__Vhl2i {
  color: #888;
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 18px;
}

.js-AdvisorGenericLogin-__index__submit_btn__-Je4- {
  background-color: #0079de;
  background-image: linear-gradient(top, #0079de 0%, #0079de 50%, #0071cf 51%, #0071cf 100%);
  cursor: pointer;
  color: #fcfcfc;
  text-shadow: 0 -1px 1px #a64767;
  text-align: center;
  border: none;
  border-radius: 6px;
  width: 108px;
  margin-top: 1em;
  padding: 10px 10px 12px;
  font-family: helvetica neue, helvetica, arial, sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 1;
}

.js-AdvisorGenericLogin-__index__footer__VmIzK {
  color: #999;
  text-align: center;
  padding: 15px 10px;
  font-size: 11px;
}

.js-AdvisorGenericLogin-__index__link__1-hYy {
  color: #16a4de;
  margin-left: 20px;
  font-size: 12px;
  text-decoration: none;
}

.js-AdvisorGenericLogin-__index__email_support_link__pSLeq {
  color: #16a4de;
  text-decoration: none;
}

.js-CustomLogin-__index__custom_login_container__juuKG * {
  box-sizing: revert;
}

.js-CustomLogin-__index__custom_login_container__juuKG h1, h2, h3, h4, h5 {
  margin: revert;
}

.js-CustomLogin-__index__custom_login_container__juuKG {
  margin-top: 20px;
  margin-left: 40px;
}

.js-Menu-__index__menu_container__o1XUr {
  z-index: 999;
  background: #151515;
  width: 160px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.js-Menu-__index__menu_item__NHdBS {
  text-align: center;
  border-bottom: 1px solid #222323;
  padding: 15px 0;
}

.js-Menu-__index__last_menu_item__9ULPs {
  border-bottom: 1px solid #ccc;
}

.js-Menu-__index__menu_item__NHdBS a {
  color: #aaa;
  font-size: 14px;
  text-decoration: none;
}

.js-Menu-__index__menu_item__NHdBS.js-Menu-__index__highlighted__9mDY6 a {
  color: #fff;
}

a {
  cursor: pointer;
}

.js-Menu-__index__account_name__fxDOA {
  color: #aaa;
  text-align: center;
  overflow-wrap: break-word;
  padding: 40px 5px 0;
  font-size: 11px;
  line-height: 16px;
}
/*# sourceMappingURL=index.94477add.css.map */
